import { ObjectToQuery } from '../../../../utils';
import { Api } from '../axios-config';

export interface IDetalheArquivosAvulsos {
	tipologiaID: number;
	documentoEletronicoID: number;
	arquivoNome: string;
	dados: string;
}

type TArquivosComTotalCount = {
	data: IDetalheArquivosAvulsos[];
	totalCount: number;
}

type TArquivosSommenteTotalCount = {
	totalCount: number;
}

const create = async (dados: Omit<IDetalheArquivosAvulsos, 'id'>): Promise<number | Error> => {
	try {
		const { data } = await Api.post<IDetalheArquivosAvulsos>('/ArquivosAvulsos/upload.php', dados);

		if (data) {
			return data.tipologiaID;
		}
		return new Error('Erro ao criar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
	}
};

const getAll = async (pagina = 1, arquivoNome = '', tipologiaId = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ pagina, arquivoNome, tipologiaId });
		const urlRelativa = `/DocumentoEletronico/ObterTodos?${params}`;
		// const urlRelativa = `/ArquivosAvulsos/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&arquivoNome=${filter}&tipologiaId=${tipologiaId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (arquivoNome = '', tipologiaId = ''): Promise<TArquivosSommenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ arquivoNome, tipologiaId });
		const urlRelativa = `/DocumentoEletronico/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const ArquivosAvulsosService = {
	create,
	getAll,
	getTotal
};