import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthService } from '../services/api/Auth/AuthService';
import { toast } from 'react-toast';
import { catchError, from, Observable, tap, throwError } from 'rxjs';
import { Token } from '../../model/Token';
import { Usuario } from '../../model/Usuario';

interface IAuthContextData {
	logout: () => void;
	isAuthenticated: boolean;
	login: (email: string, senha: string) => Observable<void>;
	usuarioLogado?: Usuario
}

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
	const [accessToken, setAccessToken] = useState<string>();
	const [usuarioLogado, setUsuarioLogado] = useState<Usuario>();

	useEffect(() => {
		const dadosTokenUsuario = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!) || '{}');
		const { accessToken } = dadosTokenUsuario as Token;
		const usuarioLocalStorage = dadosTokenUsuario as Usuario;

		setAccessToken(accessToken);
		setUsuarioLogado(usuarioLocalStorage);
	}, []);

	const handleLogin = useCallback((email: string, senha: string) => {
		return from(AuthService.authorization(email, senha))
			.pipe(
				tap((token: any) => {
					const { accessToken, tokenType, expires } = token;
					const expiresAt = new Date().getTime() + expires * 1000;
					localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!, JSON.stringify(token));
					toast.success(`Login realizado com sucesso`, {
						backgroundColor: '#006400'
					});
					setAccessToken(accessToken);
					setUsuarioLogado(token as Usuario);
				}),
				catchError((error) => {
					return throwError(() => error);
				})
			);
	}, []);

	const handleLogout = useCallback(() => {
		localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
		setAccessToken(undefined);
	}, []);

	const isAuthenticated = useMemo(() => !!accessToken, [accessToken]);

	console.log(JSON.stringify(usuarioLogado, null, 4));

	return (
		<AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout, usuarioLogado }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
