import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

// const options = [
//     'Show some love to MUI',
//     'Show all notification content',
//     'Hide sensitive notification content',
//     'Hide all notification content',
// ];


const SimpleListMenu = ({ Options, OnSelect }: { Options?: any[], OnSelect?: (pagina: any) => void }): JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		OnSelect?.(event.currentTarget);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		index: number,
	) => {
		setSelectedIndex(index);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const selecionado = Options && !!Options.length ? Options[selectedIndex].text : 'TODAS PAGINAS';

	return (
		<>
			<List
				component="nav"
				aria-label="Device settings"
				sx={{ bgcolor: 'background.paper' }}
			>
				<ListItemButton
					id="lock-button"
					aria-haspopup="listbox"
					aria-controls="lock-menu"
					aria-label="TODOS"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClickListItem}
				>
					<ListItemText
						primary={'Página Selecionada'}
						secondary={selecionado}
					/>
				</ListItemButton>
			</List>
			<Menu
				id="lock-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
			>
				<>
					<MenuItem
						key={0}
						selected={0 === selectedIndex}
						onClick={(event) => handleMenuItemClick(event, 0)}
					>
						TODAS
					</MenuItem>
					{Options && Options.map((option: any, index: number) => (
						<MenuItem
							key={option.value}
							// disabled={index === 0}
							selected={index === selectedIndex}
							onClick={(event) => handleMenuItemClick(event, index)}
						>
							{option.text}
						</MenuItem>
					))}
				</>
			</Menu>
		</>
	);
};

export default SimpleListMenu;