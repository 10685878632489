import { Api } from '../axios-config';

export interface IListagemEmpresa {
	empresaID: number;
	nome: string;
}

export interface IDetalheEmpresa {
	empresaID: number;
	nome: string;
}

type TEmpresasComTotalCount = {
	data: IListagemEmpresa[];
	totalCount: number;
}

type TEmpresasSomenteTotalCount = {
	totalCount: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TEmpresasSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/Empresa/ObterQtdeTotal`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (page = 1, filter = ''): Promise<TEmpresasComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/Empresa/ObterTodos`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheEmpresa | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const { data } = await Api.get(`/Empresa/ObterPorId?id_empresa=${id}`);

		if (data) {
			return data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

export const Empresaservice = {
	getTotal,
	getAll,
	getById
};