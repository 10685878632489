import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './routes';
import { ToastContainer, toast } from 'react-toast';
// import { Bounce, ToastContainer } from 'react-toastify';
import { AuthProvider } from './shared/contexts';
import { Login } from './pages';

import 'react-toastify/dist/ReactToastify.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';

export const App = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Login>
					<AppRoutes />
				</Login>
				<ToastContainer delay={3000} />
			</AuthProvider>
		</BrowserRouter>
	);
};
// export const App = () => {
// 	return (
// 		<BrowserRouter>
// 			<AppRoutes />
// 		</BrowserRouter>
// 	);
// };