import { useCallback, useRef } from 'react';

export const useDebounce = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce };
};

export const useDebounce1 = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce1 = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce1 };
};

export const useDebounce2 = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce2 = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce2 };
};

export const useDebounce3 = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce3 = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce3 };
};

export const useDebounce4 = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce4 = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce4 };
};

export const useDebounce5 = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounce5 = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounce5 };
};

export const useDebounceAccess = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounceAccess = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounceAccess };
};

export const useDebounceChecaLogin = (delay = 100, notDelayInFirstTime = true) => {
	const debouncing = useRef<NodeJS.Timeout>();
	const isFirstTime = useRef(notDelayInFirstTime);
	const debounceChecaLogin = useCallback((func: () => void) => {
		if (isFirstTime.current) {
			isFirstTime.current = false;
			func();
		} else {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => { func(); }, delay);
		}
	}, [delay]);
	return { debounceChecaLogin };
};
