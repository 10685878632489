import { Api } from '../axios-config';

export interface IListagemUsuario_Tipologia_Setor {
	id: number;
	tipologia_nome: string;
	nome_setor: string;
	tipologiaId: string;
	id_setor: string;
	id_tipologia_setor_empresa: string;
}

export interface IDetalheUsuario_Tipologia_Setor {
	id: number;
	tipologia_nome: string;
	nome_setor: string;
	tipologiaId: string;
	id_setor: string;
	id_tipologia_setor_empresa: string;
}

type TUsuario_Tipologia_SetorsComTotalCount = {
	data: IListagemUsuario_Tipologia_Setor[];
	totalCount: number;
}

type TUsuario_Tipologia_SetorsSomenteTotalCount = {
	totalCount: number;
}

const getTotal = async (page = 1, tipologiaId = '', id_setor = ''): Promise<TUsuario_Tipologia_SetorsSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || undefined;

		const urlRelativa = `/usuario_setor_tipologias/getTotal.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologiaId=${tipologiaId}&id_setor=${id_setor}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (page = 1, tipologiaId = '', id_setor = ''): Promise<TUsuario_Tipologia_SetorsComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || undefined;

		const urlRelativa = `/usuario_setor_tipologias/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologiaId=${tipologiaId}&id_setor=${id_setor}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheUsuario_Tipologia_Setor | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || undefined;

		const { data } = await Api.get(`/usuario_setor_tipologias/getById.php?accessToken=${accessToken}&id=${id}`);

		if (data) {
			return data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

export const Usuario_Tipologia_SetorService = {
	getTotal,
	getAll,
	getById
};