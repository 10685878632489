import { Component } from 'react';
import './App.css';

// Import React FilePond
import { FilePond, registerPlugin } from './react-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let labelIdle;
let id_tip;
let arquivoEnviadoSucesso;
let arquivoEnviadoErro;
let enviandoAquivo;
let token;

// Component
class App extends Component {

	constructor(props) {
		super(props);

		labelIdle = 'Arraste e solte arquivos aqui ou clique para selecionar!';
		id_tip = props.tipologiaId;
		arquivoEnviadoSucesso = props.arquivoEnviadoSucesso || function () { };
		arquivoEnviadoErro = props.arquivoEnviadoErro || function () { };
		enviandoAquivo = props.enviandoAquivo || function () { };
		token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN) || '{}');

		this.state = {
			// Set initial files, type 'local' means this is a file
			// that has already been uploaded to the server (see docs)
		};
	}

	handleInit() {
		console.log('FilePond instance has initialised', this.pond);
	}

	render() {
		return (

			<FilePond
				ref={(ref) => (this.pond = ref)}
				files={this.state.files}
				maxFiles={100}
				allowMultiple={true}
				allowRemove={false}
				allowProcess={false}
				allowRevert={false}
				credits='B&M'
				labelIdle={labelIdle}
				labelInvalidField='Arquivos inválidos'
				labelFileWaitingForSize='Calculando o tamanho do arquivo'
				labelFileSizeNotAvailable='Tamanho do arquivo indisponível'
				labelFileLoading='Carregando'
				labelFileLoadError='Erro durante o carregamento'
				labelFileProcessing='Enviando'
				labelFileProcessingComplete='Envio finalizado'
				labelFileProcessingAborted='Envio cancelado'
				labelFileProcessingError='Erro durante o envio'
				labelFileProcessingRevertError='Erro ao reverter o envio'
				labelFileRemoveError='Erro ao remover o arquivo'
				labelTapToCancel='clique para cancelar'
				labelTapToRetry='clique para reenviar'
				labelTapToUndo='' // clique para desfazer
				labelButtonRemoveItem='' // Remover
				labelButtonAbortItemLoad='Abortar'
				labelButtonRetryItemLoad='Reenviar'
				labelButtonAbortItemProcessing='Cancelar'
				labelButtonUndoItemProcessing='' // Desfazer
				labelButtonRetryItemProcessing='Reenviar'
				labelButtonProcessItem='Enviar'
				labelMaxFileSizeExceeded='Arquivo é muito grande'
				labelMaxFileSize='O tamanho máximo permitido= {filesize}'
				labelMaxTotalFileSizeExceeded='Tamanho total dos arquivos excedido'
				labelMaxTotalFileSize='Tamanho total permitido= {filesize}'
				labelFileTypeNotAllowed='Tipo de arquivo inválido'
				fileValidateTypeLabelExpectedTypes='Tipos de arquivo suportados são {allButLastType} ou {lastType}'
				imageValidateSizeLabelFormatError='Tipo de imagem inválida'
				imageValidateSizeLabelImageSizeTooSmall='Imagem muito pequena'
				imageValidateSizeLabelImageSizeTooBig='Imagem muito grande'
				imageValidateSizeLabelExpectedMinSize='Tamanho mínimo permitida= {minWidth} × {minHeight}'
				imageValidateSizeLabelExpectedMaxSize='Tamanho máximo permitido= {maxWidth} × {maxHeight}'
				imageValidateSizeLabelImageResolutionTooLow='Resolução muito baixa'
				imageValidateSizeLabelImageResolutionTooHigh='Resolução muito alta'
				imageValidateSizeLabelExpectedMinResolution='Resolução mínima permitida= {minResolution}'
				imageValidateSizeLabelExpectedMaxResolution='Resolução máxima permitida= {maxResolution}'
				server={{
					url: process.env.REACT_APP_URL_BASE,
					process: {
						url: `/Arquivo/UploadFiles?tipologiaId=${id_tip}`,
						method: 'POST',
						withCredentials: false,
						headers: {
							'Authorization': `Bearer ${token.accessToken}`
						},
						timeout: 60000,
						onload: () => arquivoEnviadoSucesso(this.state.files),
						onerror: (error) => arquivoEnviadoErro(error),
						ondata: (formData) => {
							enviandoAquivo();
							return formData;
						},
					},
					revert: (item, load, error) => {
						console.log(this.state);
					}
				}}
				oninit={() => this.handleInit()}
				onupdatefiles={(fileItems) => {
					// Set currently active file objects to this.state
					this.setState({
						files: fileItems.map((fileItem) => fileItem.file),
					});
				}}
			></FilePond>
		);
	}
}

export default App;