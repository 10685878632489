import { createTheme } from '@mui/material';
import { cyan, yellow } from '@mui/material/colors';

export const LightTheme = createTheme({
	palette: {
		primary: {
			main: '#1976d2',
			dark: '#1565c0',
			light: '#42a5f5',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#0288d1',
			dark: '#01579b',
			light: '#03a9f4',
			contrastText: '#ffffff'
		},
		background: {
			default: '#f7f6f3',
			paper: '#ffffff'
		}
	}
});