import { ERole } from '../enums';

export class Mensagem {
	role?: ERole;
	content?: string;
	user?: string;
	timestamp?: Date;
	backgroundColor?: string;
	justifyContent?: string;

	constructor(dto: any) {
		this.role = dto?.role;
		this.content = dto?.content;
		this.user = dto?.user;
		this.timestamp = dto?.timestamp;
		this.backgroundColor = dto?.backgroundColor;
		this.justifyContent = dto?.justifyContent;
	}
}