import { Api } from '../axios-config';

export interface IListagemTipologia {
	tipologiaID: number;
	nome: string;
}

export interface IDetalheTipologia {
	tipologiaID: number;
	nome: string;
}

type TTipologiasComTotalCount = {
	data: IListagemTipologia[];
	totalCount: number;
}

type TTipologiasSomenteTotalCount = {
	totalCount: number;
}

export interface IListagemTipologiaCampos {
	tipologiaID: string;
	fieldName: string;
	ordem: string;
	tipoID: string;
	titulo: string;
}

type TTipologiaCampos = {
	data: IListagemTipologiaCampos[];
	totalCount: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TTipologiasSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/Tipologia/ObterQtdeTotal';
		// const urlRelativa = `/tipologias/getTotal.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologia_nome_like=${filter}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (page = 1, filter = ''): Promise<TTipologiasComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/Tipologia/ObterTodos';
		// const urlRelativa = `/tipologias/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologia_nome_like=${filter}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllFiels = async (): Promise<IListagemTipologiaCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/TipologiaCampos/ObterTodos';
		// const urlRelativa = `/tipologias/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&tipologia_nome_like=${filter}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getFields = async (tipologiaId = '', processoId = ''): Promise<IListagemTipologiaCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/TipologiaCampos/ObterCamposPorTipologiaId?tipologiaId=${tipologiaId}&processoId=${processoId}`;
		// const urlRelativa = `/tipologias/getFields.php?accessToken=${accessToken}&tipologiaId=${tipologiaId}&processoId=${processoId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheTipologia | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const { data } = await Api.get(`/Tipologia/ObterPorId?tipologiaId=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

export const TipologiaService = {
	getTotal,
	getAll,
	getFields,
	getById,
	getAllFiels
};