import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './interceptors';
import { Token } from '../../../../model/Token';

const Api = axios.create({
	baseURL: process.env.REACT_APP_URL_BASE
});

Api.interceptors.response.use(
	(response) => responseInterceptor(response),
	(error) => errorInterceptor(error),

);

Api.interceptors.request.use(
	config => {
		const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!) || '{}') as Token;
		if (!config.url?.includes('auth/token'))
			config.headers['Authorization'] = `Bearer ${token.accessToken}`;

		return config;
	},
	error => error,
);

export { Api };