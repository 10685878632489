const ObjectToQuery = (obj: any, prefix: any = null): string => {
	const str = [];
	let p;
	for (p in obj) {
		if (p in obj && obj[p]) {
			const k = prefix ? prefix + '[' + p + ']' : p, v = obj[p];
			str.push((v !== null && typeof v === 'object') ?
				ObjectToQuery(v, k) :
				encodeURIComponent(k) + '=' + encodeURIComponent(v));
		}
	}
	return str.join('&');
};

export {
	ObjectToQuery
};