import { Pagination, Paper, TableFooter, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, IconButton, Icon, Box, Stack, CircularProgress, Typography, Grid, Checkbox, FormControlLabel, Card, CardContent, FormControl, FormGroup, Button, FormLabel, InputLabel, ListItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from '../../shared/components';
import { useDebounce1, useDebounce2, useDebounce3 } from '../../shared/hooks';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { IDetalheArquivosProcessos, ArquivosProcessosService } from '../../shared/services/api/ArquivosProcessos/ArquivosProcessosService';
import { IListagemTipologia, TipologiaService } from '../../shared/services/api/Tipologias/TipologiasService';

import './folder.css';
import './mapLinks.css';

export const ListagemDeArquivosProcessos: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [totalCount, setTotalCount] = useState(0);
	const navigate = useNavigate();

	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isLoadingTipologia, setIsLoadingTipologia] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [rowsTipologia, setRowsTipologia] = useState<IListagemTipologia[]>([]);
	const [rowsFiles, setRowsFiles] = useState<IDetalheArquivosProcessos[]>([]);
	const { debounce1 } = useDebounce1(50, false);
	const { debounce2 } = useDebounce2(50, false);
	const { debounce3 } = useDebounce3(50, false);
	const [listtipologias, setlisttipologias] = useState('');
	const [filtrarVermelho, setFiltrarVermelho] = useState(false);
	const [tipologianome, SetTipologiaNome] = useState('0');

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	useEffect(() => {
		/* ************************************************************************** */
		debounce1(() => {
			if (isLoadingTipologia) {
				TipologiaService.getById(Number(listtipologias))
					.then((result) => {
						setIsLoadingTipologia(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							SetTipologiaNome(result?.nome);
						}
					});
			}
		});
		debounce2(() => {
			if (isLoadingTipologias) {
				TipologiaService.getAll()
					.then((result) => {
						setIsLoadingTipologias(false);
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setRowsTipologia(result.data);
						}
					});
			}
		});
		debounce3(() => {
			setIsLoading(true);
			ArquivosProcessosService.getAllWithDocs(pagina, busca, listtipologias)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsFiles(result.data);
						busca.length > 0 ? setFiltrarVermelho(true) : null;
					}
				});
			ArquivosProcessosService.getTotal(busca, listtipologias)
				.then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setTotalCount(result.totalCount);
					}
				});
		});
	}, [busca, pagina, listtipologias]);

	const geralist = (t: string) => {
		if (t == listtipologias) return;
		setlisttipologias(t);
		setIsLoadingTipologia(true);
		setSearchParams({ busca: busca, pagina: '1', listtipologias: t }, { replace: true }); // Gera lista string de marcados

	};

	useEffect(() => {
		const tipologiaId = searchParams.get('listtipologias');
		if (tipologiaId) {
			setlisttipologias(tipologiaId);
			setIsLoadingTipologia(true);
		}
	}, [searchParams]);

	/* ************************************************************************************** */

	return (
		<LayoutBaseDePagina>
			<Stack direction='row'>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '25%' }}>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>
									<Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" sx={{ m: 0, width: '100%' }}>
										<Card>
											<CardContent>
												<FormControl component="fieldset">
													<FormGroup aria-label="position">
														<Box justifyContent='end' height={70} >
															<Button
																color='info'
																disableElevation
																variant='contained'
																onClick={() => { alert('Cadastro de novo processo em breve!'); }}
																startIcon={<Icon>add</Icon>} >
																<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
																	Processo
																</Typography>
															</Button>
														</Box>
														<Grid item xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
															<Typography variant='button' whiteSpace='nowrap'
																textOverflow='ellipsis' overflow='hidden' fontSize={14} >
																Tipologias
															</Typography>
														</Grid>
														{isLoadingTipologias ? <CircularProgress /> : null}
														{!isLoadingTipologias ? rowsTipologia.map((row, keynum) => {
															return (
																<Grid item key={keynum} xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem' }}>
																	<Box sx={{ '&:hover': { cursor: 'pointer' } }}
																		onClick={() => geralist(row.tipologiaID.toString())} >
																		<Typography variant='caption' whiteSpace='nowrap'
																			textOverflow='ellipsis' overflow='hidden' fontSize={14} fontWeight={listtipologias == row.tipologiaID.toString() ? 'bold' : ''}>
																			{row.nome}
																		</Typography>
																	</Box>
																</Grid>
															);
														}) : null}
													</FormGroup>
												</FormControl>
											</CardContent>
										</Card>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<TableContainer component={Paper} variant='outlined' sx={{ m: 1, width: '75%' }}>
					<Table>
						<TableHead>
							<TableRow>
								<div className='mapFolder'>
									<div className='mapMain'>
										<div className='mapLink' onClick={() => navigate('/pesquisar')}>Home</div>
										<div className='mapLink' onClick={() => navigate('/pesquisar')}>&nbsp;/ Tipologia</div>&nbsp;
									</div>
								</div>
							</TableRow>
							<TableRow>
								<TableCell colSpan={2} align='center'>
									<FerramentasDaListagem
										mostrarInputBusca={true}
										mostrarBotaoVoltar={false}
										/* aoClicarEmVoltar={() => navigate('/Dashboard')} */
										mostrarBotaoNovo={false}
										textoBotaoNovo='Novo'
										textoDaBusca={searchParams.get('busca') ?? ''}
										aoClicarEmNovo={() => navigate('/pesquisar')}
										aoMudarTextoDeBusca={texto => { setSearchParams({ busca: texto, pagina: '1', tipologiaId: listtipologias }, { replace: true }); setFiltrarVermelho(false); }}
									/>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell >
									{isLoadingTipologia && (
										<CircularProgress />
									)}
									<Box display="flex" p={1} width={300}>
										{!isLoadingTipologia && tipologianome != '' && (
											<Box p={1} flexGrow={1}>
												<Card variant="outlined" sx={{
													boxShadow: 1,
													borderRadius: 1,
													p: 1,
													minWidth: 150,
													maxWidth: 200,
												}} >

													<Typography key={0} fontSize={10} align='left' color='#085784' fontWeight='bold'>
														{tipologianome}
													</Typography>

												</Card>
											</Box>
										)}
										<Box p={1}>
											<Box flex={1} textAlign={'left'}>
												<Button
													color='info'
													disableElevation
													variant='contained'
													onClick={() => navigate('/pesquisar')}
													startIcon={<Icon>add</Icon>}
												>Novo</Button>
											</Box>
										</Box>
									</Box>
								</TableCell>
							</TableRow>

							<TableRow>
								<TableCell width={'90%'}>Processos</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>
									<div className='Main'>
										{isLoading ? <CircularProgress /> : null}
										{!isLoading ? rowsFiles.map((rowFiles, keynum) => {
											const arrProcesso_nome = rowFiles.nome?.split(new RegExp(busca, 'i'));
											return (
												<Box key={keynum} sx={{ '&:hover': { cursor: 'pointer' } }}
													onClick={() => navigate('/listararquivosavulsosDetalhado/' + rowFiles.processoID)} >
													<div className='Folder'>
														<Typography variant='h4' fontSize={14} >
															{busca.length >= 1 && arrProcesso_nome.map((t: string, i: number) => {
																return (
																	<Box component="span" key=''>
																		{t.substring(t.length - 300)}<Box
																			component="span"
																			sx={{
																				p: 0,
																				color: filtrarVermelho ? 'red' : '',
																				textDecoration: filtrarVermelho ? 'underline' : '',
																				fontWeight: filtrarVermelho ? 'bold' : ''
																			}}>
																			{i < arrProcesso_nome.length - 1 && busca}
																		</Box>
																	</Box>
																);
															})}
															{busca.length <= 0 && rowFiles.nome}
														</Typography>
													</div>
												</Box>
											);
										}) : null}
									</div>
								</TableCell>
							</TableRow>
						</TableBody>
						<TableFooter>
							{totalCount === 0 && !isLoading ? (
								<TableRow>
									<TableCell colSpan={2}>
										{process.env.REACT_APP_LISTAGEM_VAZIA}
									</TableCell>
								</TableRow>
							) : null}
							{totalCount > 0 && totalCount > parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!) ? (
								<TableRow>
									<TableCell colSpan={2}>
										<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
											<Pagination
												page={pagina}
												count={Math.ceil(totalCount / parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!))}
												onChange={(_, newPage) => setSearchParams({ busca: busca, pagina: newPage.toString(), tipologiaId: listtipologias }, { replace: true })}
											/>
										</Box>
									</TableCell>
								</TableRow>
							) : null}
						</TableFooter>
					</Table >
				</TableContainer >
			</Stack >
			<Grid container xs={12} sm={12} md={12} lg={12} xl={14} sx={{ padding: '1rem', justifyContent: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};