import { ObjectToQuery } from '../../../../utils';
import { Api } from '../axios-config';

export interface IDetalheArquivosProcessos {
	processoID: number;
	nome: string;
}

type TArquivosComTotalCount = {
	data: IDetalheArquivosProcessos[];
	totalCount: number;
}

type TArquivosSommenteTotalCount = {
	totalCount: number;
}

const getAllWithDocs = async (pagina = 1, arquivoNome = '', tipologiaId = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ pagina, arquivoNome, tipologiaId });
		const urlRelativa = `/Processo/ObterComDocumentos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (pagina = 1, arquivoNome = '', tipologiaId = ''): Promise<TArquivosComTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ pagina, arquivoNome, tipologiaId });
		const urlRelativa = `/DocumentoCampos/ObterArquivosProcesso?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (arquivoNome = '', tipologiaId = ''): Promise<TArquivosSommenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const params = ObjectToQuery({ arquivoNome, tipologiaId });
		const urlRelativa = `/DocumentoCampos/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const ArquivosProcessosService = {
	getAllWithDocs,
	getAll,
	getTotal
};