import { Mensagem } from '../../../../model';
import { Api } from '../axios-config';

export interface IChatIA {
	resposta: string;
}

const doAQuestionToIA = async (mensagens: Mensagem[]): Promise<IChatIA> => {
	try {
		const { data } = await Api.post('/ChatIA/Pergunta', { mensagens: mensagens.map(({ role, content }) => ({ role, content })) });

		if (data?.data)
			return data.data;

		console.log('Erro(s): ', data.errors.join('<br/>'));
		return { resposta: 'Desculpe, algo deu errado. Tente novamente mais tarde.' };
	} catch (error) {
		return { resposta: 'Desculpe, algo deu errado. Tente novamente mais tarde.' };
	}
};

const doAQuestionToIAWithMessagens = async (mensagens: Mensagem[]): Promise<IChatIA> => {
	try {
		const { data } = await Api.post('/ChatIA/Chat', { mensagens: mensagens });

		if (data?.data)
			return data.data;

		console.log('Erro(s): ', data.errors.join('<br/>'));
		return { resposta: 'Desculpe, algo deu errado. Tente novamente mais tarde.' };
	} catch (error) {
		return { resposta: 'Desculpe, algo deu errado. Tente novamente mais tarde.' };
	}
};

export const ChatIAService = {
	doAQuestionToIA,
	doAQuestionToIAWithMessagens
};